<template>
  <div class="panel-block" v-bind:class="{'disabled': element.loading}">
             <span class="icon is-pulled-left handle" v-if="canMove">
              <ion-icon name="move-outline"></ion-icon>
            </span>

    <input type="checkbox" v-model="element.checked" v-on:change="updateCheckbox(element)">
    <div class="field is-horizontal has-addons">
      <div class="control">
        <input class="input" type="text" v-bind:disabled="element.checked" v-model="element.content"
               v-on:blur="updateContent(element)">
      </div>
      <div class="control">
        <a class="button is-danger" v-on:click="deleteElement(element)">
          <ion-icon name="trash"></ion-icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
  props: ['item', 'canMove'],
  data: function () {
    return {
      element: this.item
    }
  },
  methods: {
    updateCheckbox: function () {
      this.$emit('updateItem', {id: this.element.id, checked: this.element.checked}, true)
    },
    updateContent: function () {
      this.$emit('updateItem', {id: this.element.id, content: this.element.content}, true)
    },
    deleteElement: function () {
      this.$emit('deleteElement', this.element.id)
    }
  }
}
</script>

<style scoped>

.panel-block.disabled {
  opacity: .5;
}

.field, .field .control:first-child {
  width: 100%;
}
</style>
