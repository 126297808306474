<template>
  <div class="block">
    <div class="panel is-info" v-if="!loading">
      <p class="panel-heading">
      <span class="icon is-pulled-left" v-on:click="$router.back()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </span>
        {{ this.listName }}
      </p>
      <div class="panel-block">
        <div class="field is-grouped">
          <p class="control is-expanded  has-icons-left">
            <input class="input" type="text" v-model="searchQ" placeholder="Search" v-on:keyup="searchItem">
            <span class="icon is-left">
            <ion-icon name="search-outline"></ion-icon>
          </span>
          </p>
          <p class="control">
            <button class="button is-link" v-on:click="createItem">
          <span class="icon is-medium">
            <ion-icon name="add-outline"></ion-icon>
          </span>
            </button>
          </p>
        </div>
      </div>

      <draggable
          v-model="uncheckedItems"
          group="listItems"
          @change="onChange"
          item-key="id" v-if="searchQ.length <= 0"
          handle=".handle">
        <template #item="{element}">
          <ListItem v-bind:item="element" v-bind:can-move="!element.checked" v-on:updateItem="updateItem" v-on:deleteElement="deleteElement"></ListItem>
        </template>
      </draggable>
      <div v-if="searchQ.length <= 0">
        <ListItem v-for="element in checkedItems" v-bind:can-move="false" :key="element.id" v-bind:item="element" v-on:updateItem="updateItem" v-on:deleteElement="deleteElement"></ListItem>
      </div>
      <div v-if="searchQ.length > 0">
        <ListItem v-for="element in searchResults" v-bind:can-move="false" :key="element.id" v-bind:item="element" v-on:updateItem="updateItem" v-on:deleteElement="deleteElement"></ListItem>
      </div>

    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ListItem from "@/components/ListItem";

export default {
  name: "List",
  components: {ListItem, draggable},
  data: function () {
    return {
      listId: this.$route.params.id,
      listName: '',
      searchQ: '',
      searchResults: [],
      uncheckedItems: [],
      checkedItems: [],
      initialItems: [],
      loading: true,
      addModalShown: false,
      drag: false
    }
  },
  methods: {
    fetchListAndItems: function (ignoreLoading) {
      if (!ignoreLoading) {
        this.loading = true;
      }
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/lists/` + this.listId + `/items`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'X-API-KEY': localStorage.getItem('KEY')
        }
      }).then(r => {
        return r.json();
      }).then(result => {
        this.listName = result.name;
        this.initialItems = result.items.sort((a, b) => a.position - b.position);
        this.recopyItemsObject();
      }).finally(() => {
        this.loading = false;
      })
    },
    updateItem: function (item, reloadAll) {
      item.loading = true;
      const itemCopy = Object.assign({}, item);
      delete itemCopy.loading;
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/lists/` + this.listId + `/items/` + item.id, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'X-API-KEY': localStorage.getItem('KEY')
        },
        body: JSON.stringify(itemCopy)
      }).finally(() => {
        if (reloadAll) {
          this.fetchListAndItems(true);
        }
        item.loading = false;
      })
    },
    deleteElement: function (itemId) {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/lists/` + this.listId + `/items/` + itemId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'X-API-KEY': localStorage.getItem('KEY')
        }
      }).finally(() => {
        this.initialItems = this.initialItems.filter(i => i.id !== itemId)
        this.recopyItemsObject();
      })
    },
    recopyItemsObject: function () {
      this.uncheckedItems = this.initialItems.filter(x => !x.checked).map(x => ({...x}));
      this.checkedItems = this.initialItems.filter(x => x.checked).map(x => ({...x}));
    },
    searchItem: function (e) {
      if (e.key === 'Enter') {
        this.createItem();
      } else {
        if (this.searchQ.length > 0) {
          this.searchResults = this.initialItems.filter(i => i.content.toLowerCase().indexOf(this.searchQ.toLowerCase()) >= 0)
        } else {
          this.recopyItemsObject();
        }
      }
    },
    onChange: function (event) {
      if (event.moved) {
        this.updateItem({
          id: event.moved.element.id,
          position: event.moved.newIndex
        }, true);
      }
    },
    createItem: function () {
      if (this.searchResults.length == 1) {
        this.searchResults[0].checked = false;
        this.searchQ = '';
        const itemToSend = {
          id : this.searchResults[0].id,
          checked: this.searchResults[0].checked
        };
        this.updateItem(itemToSend, true);
      } else {
        fetch(`${process.env.VUE_APP_API_BASE_URL}/api/lists/` + this.listId + `/items`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'X-API-KEY': localStorage.getItem('KEY')
          },
          body: JSON.stringify({content: this.searchQ})
        }).then(r => {
          return r.json();
        }).then(() => {
          this.searchQ = '';
          this.fetchListAndItems(true);
        })
      }
    }
  },
  beforeMount() {
    this.fetchListAndItems();
  }
}
</script>

<style scoped>

.field, .field .control:first-child {
  width: 100%;
}
</style>
